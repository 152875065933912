import { graphql } from "gatsby";
import ProjectComponent from "@lekoarts/gatsby-theme-emilia/src/components/project";

export default ProjectComponent;

export const query = graphql`
  query($slug: String!, $absolutePathRegex: String!, $formatString: String!) {
    images: allFile(
      filter: {
        absolutePath: { regex: $absolutePathRegex }
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(gif)|(mp4)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        publicURL
      }
    }
    project(slug: { eq: $slug }) {
      body
      excerpt
      date(formatString: $formatString)
      slug
      title
      areas
      cover {
        name
        childImageSharp {
          resize(width: 800, quality: 100) {
            src
          }
        }
      }
    }
  }
`