// import React from 'react';
// import Project from '@lekoarts/gatsby-theme-emilia/src/components/project';

// const CustomProject = props => (
//   <>
//     <Project {...props} />
//     <span>Gatsby theme</span>&nbsp;Carbon
  
//   </>

// );
// export default CustomProject;

/** @jsx jsx */
import { jsx, Container, Image } from "theme-ui"
import { animated, useSpring, config } from "react-spring"
import Img from "gatsby-image"
import { ChildImageSharpFluid } from "../types"
import Layout from "@lekoarts/gatsby-theme-emilia/src/components/layout"
import HeaderProject from "@lekoarts/gatsby-theme-emilia/src/components/header-project"
import ProjectPagination from "@lekoarts/gatsby-theme-emilia/src/components/project-pagination"
import SEO from "@lekoarts/gatsby-theme-emilia/src/components/seo"

type ProjectProps = {
  data: {
    project: {
      body: string
      excerpt: string
      date: string
      slug: string
      title: string
      areas: string[]
      cover: {
        name: string
        childImageSharp: {
          resize: {
            src: string
          }
        }
      }
    }
    images: {
      nodes: {
        name: string
        publicURL: string
      }[]
    }
  }
  pageContext: {
    prev: {
      slug: string
      parent: {
        fileAbsolutePath: string
      }
      title: string
      cover: {
        childImageSharp: ChildImageSharpFluid
      }
    }
    next: {
      slug: string
      parent: {
        fileAbsolutePath: string
      }
      title: string
      cover: {
        childImageSharp: ChildImageSharpFluid
      }
    }
  }
}

const Project = ({ data: { project, images }, pageContext: { prev, next } }: ProjectProps) => {
  const imageFade = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })
  const projectCover = project.cover.name; 

  return (
    <Layout>
      <SEO
        title={project.title}
        description={project.excerpt}
        pathname={project.slug}
        image={project.cover.childImageSharp.resize.src}
      />
      <HeaderProject title={project.title} description={project.body} areas={project.areas} date={project.date} />
      <Container sx={{ mt: [`-6rem`, `-6rem`, `-8rem`] }}>
        <Container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          {images.nodes.map((image) => {
            if(image.name !== projectCover)
              return (
                <animated.div key={image.name} style={imageFade}>
                  {
                    image.publicURL.includes('.mp4') ? (<video controls style={{marginBottom: '4rem', width: `37vw`, maxHeight: `720px` }}> <source src={image.publicURL} type="video/mp4"></source> </video>) 
                    : <Image src={image.publicURL} alt={image.name} sx={{ width: `45vw`, mb: [4, 4, 5], boxShadow: `xl` }} />
                  }
                </animated.div>
              )
          })}
        </Container>
        <ProjectPagination prev={prev} next={next} />
      </Container>
    </Layout>
  )
}

export default Project
